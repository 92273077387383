import dotenv from "dotenv";
dotenv.config();

export const HOST = window.location.host;

const getApolloURI = {
  "easymd.co": process.env.REACT_APP_APOLLO_URI_PROD,
  "www.easymd.co": process.env.REACT_APP_APOLLO_URI_PROD,
  "easy-md-front.herokuapp.com": process.env.REACT_APP_APOLLO_URI_DEV,
  "staging.dtb4el48irs2f.amplifyapp.com": process.env.REACT_APP_APOLLO_URI_DEV,
  "main.dtb4el48irs2f.amplifyapp.com": process.env.REACT_APP_APOLLO_URI_PROD,
  "bioanalisis.siapvital.com": process.env.REACT_APP_APOLLO_URI_PROD,
  "localhost:3000": process.env.REACT_APP_APOLLO_URI_DEV,
  "192.168.1.21:3000": process.env.REACT_APP_APOLLO_URI_DEV,
  "44.212.221.159": process.env.REACT_APP_APOLLO_URI_PROD,
};

export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const LOCAL_HOST = "localhost:3000";
export const DEFAULT_VIEW_USER = "/admin/dashboard";
export const DEFAULT_VIEW_ADMIN = "/md/home";
export const LANDING_URL = process.env.REACT_APP_LANDING_URL;
export const URI = getApolloURI[HOST];
