import React, { useState, useEffect, useContext } from "react";

import LlamadaDiv from "../components/video/indexLlamada";
import { XMPP } from "../../../xmpp";
import { SET_CHATCALL_SESSIONID } from "../../../store/actions";

import { GlobalContext } from "../../App";
import { getRandomInt } from "../components/utils";

const LlamadaListenerConfig = ({ infoAppointment, infoForChat }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { chatCall: chatCallGlobalState } = state;
  const [randomForClose, setRandomForClose] = useState(null);
  const [finalSessionId, setFinalSessionId] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);

  useEffect(() => {
    verificarLlamada();
  }, [chatCallGlobalState]);

  useEffect(() => {
    return () => {
      terminarSessionLlamada();
      dispatch({
        type: SET_CHATCALL_SESSIONID,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (randomForClose) {
      terminarSessionLlamada();
    }
  }, [randomForClose]);

  const terminarSessionLlamada = () => {
    try {
      const activeCall = XMPP.getActiveCall();
      if (activeCall) {
        XMPP.endCall(activeCall?.sessionId).then(
          () => {
            setFinalSessionId(null);
            setCallInProgress(false);
            dispatch({
              type: SET_CHATCALL_SESSIONID,
              payload: null,
            });
          },
          () => {
            setFinalSessionId(null);
            setCallInProgress(false);
            dispatch({
              type: SET_CHATCALL_SESSIONID,
              payload: null,
            });
          }
        );
      }
    } catch (e) {
      setFinalSessionId(null);
      setCallInProgress(false);
      dispatch({
        type: SET_CHATCALL_SESSIONID,
        payload: null,
      });
    }
  };

  const verificarLlamada = () => {
    const chatSession = chatCallGlobalState ? chatCallGlobalState.sessionID : null;
    setFinalSessionId(chatSession);
    if (chatSession) {
      setCallInProgress(true);
    } else {
      setCallInProgress(false);
    }
  };

  const listenerLlamada = () => {
    XMPP.addCallListener(
      "CALL_LISTENER_PATIENT",
      new XMPP.CallListener({
        onOutgoingCallRejected(call) {
          setFinalSessionId(null);
          setCallInProgress(false);
          setRandomForClose(getRandomInt());
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
        onIncomingCallCancelled(call) {
          setFinalSessionId(null);
          setCallInProgress(false);
          setRandomForClose(getRandomInt());
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
      })
    );
  };

  const disconnectCall = () => {
    setCallInProgress(false);
  };

  return (
    <div className='h-full w-full'>
      <div className='flex flex-col justify-center content-center w-full h-full'>
        {callInProgress ? (
          <LlamadaDiv
            sessionID={finalSessionId}
            terminarSessionLlamada={terminarSessionLlamada}
            listenerEmpezarLlamada={listenerLlamada}
            infoAppointment={infoAppointment}
            infoForChat={infoForChat}
            disconnectCall={disconnectCall}
          />
        ) : (
          <CustomNoCall />
        )}
      </div>
    </div>
  );
};

const CustomNoCall = () => {
  return (
    <>
      <p className='text-center text-2xl font-bold text-success mt-3'>Consultas inmediatas</p>
      <p className='text-center text-2xl font-bold text-success'>
        Beneficio de los Planes BioAnalisis
      </p>
    </>
  );
};

export default React.memo(LlamadaListenerConfig);
