import React from "react";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkedAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function ContactInfo() {
  return (
    <div style={{ maxWidth: 480 }}>
      <div className='flex mb-4'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faMapMarkedAlt} />
        <a
          className=' text-white'
          target='_blank'
          rel='noopener noreferrer'
          href='https://g.page/OficentroMetropolitano?share'
        >
          Km 4½ C. Masaya Contiguo a Hyundai (Frente a Burger King Carretera Masaya)
        </a>
      </div>

      <div className='flex mb-4'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faPhone} />
        <div className='flex flex-col'>
          <a className='text-white mb-2' href='tel:++505578726684'>
            +(505) 2277 1049
          </a>
          <a className='text-white' href='tel:+50578495825'>
            +(505) 2278 6350
          </a>
        </div>
      </div>

      <div className='flex'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faEnvelope} />
        <a className='text-white' href='mailto:info@easymd.co'>
          info@bioanalisis.com.ni
        </a>
      </div>
    </div>
  );
}
