import React from "react";
import Lottie from "react-lottie";
import easyMDLoading from "../../../assets/lottie/easyMD_loading.json";

const InitialSteps = () => {
  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: easyMDLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className='flex justify-center w-full my-10'>
        <div className='flex justify-center items-center'>
          <Lottie isClickToPauseDisabled width='85%' options={defaultOptionsAnimation} />
        </div>
      </div>
      <div className='h-35 flex justify-center items-center'>
        <p className='text-center text-lg'>
          A través de los planes BioAnalisis tendrás acceso a servicios de salud digitales desde la
          comodidad de tu casa o trabajo.
        </p>
      </div>
    </>
  );
};

export default InitialSteps;
