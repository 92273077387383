import React from "react";
import { Layout } from "antd";
import HeaderHome from "./headerHome";
import FooterHome from "./footer";
import WhatsAppChat from "../../components/WhatsAppChat";
import LoginMD from "../../views/microSalud/Login";

const { Content } = Layout;

const HomeLayout = () => {
  return (
    <Layout className='layout bg-white'>
      <HeaderHome />
      <Content>
        <div className='site-layout-content'>
          <LoginMD />
        </div>
      </Content>
      <FooterHome />
      <WhatsAppChat />
    </Layout>
  );
};

export default HomeLayout;
