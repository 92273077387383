import React, { useEffect } from "react";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./style.module.scss";
import { Button } from "antd";

const NotificationMessage = ({ info, redirect }) => {
  useEffect(() => {
    document.title = "Nuevo mensaje sin leer";
    const interval = setInterval(() => {
      const title = document.title;
      if (title === "BioAnalisis") {
        document.title = "Nuevo mensaje sin leer";
      } else {
        document.title = "BioAnalisis";
      }
    }, 1200);
    return () => {
      clearInterval(interval);
      document.title = "BioAnalisis";
    };
  }, []);
  const clickRedirect = () => {
    const { appointmentId, type } = info;
    if (appointmentId) {
      let url = "/cita/doctor/consultainmediata/" + appointmentId;
      if (type === "specialist") {
        url = "/cita/doctor/llamada/" + appointmentId;
      }
      redirect(url);
    }
  };
  return (
    <div className={"flex flex-row justify-items-center items-center w-full " + styles.newMessage}>
      <Button className='mr-auto button-success' type='default' onClick={() => clickRedirect()}>
        Ir a la sala de espera{" "}
      </Button>
      <FontAwesomeIcon className='faMessage' icon={faEnvelopeOpenText} />
    </div>
  );
};

export default NotificationMessage;
