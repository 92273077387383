import React, { useEffect, useContext, useCallback } from "react";
import FormLogin from "../../../components/FormLogin";
import { notification } from "antd";

import { useHistory } from "react-router-dom";

import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Lottie from "react-lottie";
import loginAnimation from "../../../assets/lottie/login.json";
import { HandleError } from "utils.js/HandleError";

import { GlobalContext } from "views/App";
import { SET_USER } from "../../../store/actions";
import logo from "assets/img/bioanalisisLogo.png";

// utils
import { logoutAndClearAll } from "layouts/utils";

const login = gql`
  query login($email: String!, $password: String!, $phoneNumber: String) {
    login(email: $email, password: $password, phoneNumber: $phoneNumber) {
      token
      refreshToken
    }
  }
`;

const me = gql`
  query me {
    me {
      id
      firstName
      lastName
      fullName
      verified
      email
      sex
      country
      openFireUsername
      openFirePassword
      beneficiaryOf
      belongsToCompany {
        id
      }
      phoneNumber
      appointmentMD {
        id
        reasonForConsultation
      }
      appointmentIC {
        id
        reasonForConsultation
      }
      plans {
        plan {
          id
          name
          beneficiaries
        }
      }
      permissions {
        id
        name
      }
      pictureURL {
        url
      }
      role {
        id
        name
        usedFor
      }
    }
  }
`;

const LoginMD = () => {
  const { dispatch } = useContext(GlobalContext);
  const history = useHistory();

  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: loginAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [fetchLogin, { loading, error, data }] = useLazyQuery(login, { fetchPolicy: "no-cache" });

  const [sendMe, { loading: loadingME, error: errorME, data: dataME }] = useLazyQuery(me, {
    fetchPolicy: "no-cache",
  });
  const roleName = dataME?.me?.role?.name;
  const userGroup = dataME?.me?.role?.usedFor;

  const resetUser = useCallback(() => {
    logoutAndClearAll();
    dispatch({
      type: SET_USER,
      payload: null,
    });
    notification.error({
      message: "Error interno de servidor",
      description: "Por favor inténtelo de nuevo!",
    });
  }, [dispatch]);

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    } else {
      console.log(data, "DATA");
      if (data && !loading) {
        try {
          localStorage.setItem("token", data.login.token);
          localStorage.setItem("refreshToken", data.login.refreshToken);
          sendMe();
        } catch (e) {
          resetUser();
        }
      }
    }
  }, [loading, error, data, resetUser, sendMe]);

  useEffect(() => {
    if (errorME && !loadingME) {
      notification.error({
        message: "Error",
        description: HandleError(errorME),
      });
    } else {
      if ((userGroup || roleName) && !loadingME) {
        try {
          dispatch({
            type: SET_USER,
            payload: dataME.me,
          });
          if (userGroup === "adminRole" || userGroup === "operationsRole") {
            history.push("/admin");
          } else if (roleName === "doctor" || roleName === "doctorMD") {
            history.push("/medic");
          } else {
            history.push("/patient");
          }
        } catch (e) {
          resetUser();
        }
      }
    }
  }, [dataME, roleName, userGroup, loadingME, errorME, resetUser, dispatch, history]);

  const onFinishForm = (values) => {
    try {
      fetchLogin({ variables: values });
    } catch (error) {
      notification.error({
        message: "Internal server error ",
        description: "Error al iniciar sesión!",
      });
    }
  };

  return (
    <div className='h-full lg:h-screen grid grid-cols-12 gap-4 container mx-auto'>
      <div className='col-span-12 md:col-span-12 lg:col-span-6 flex items-center'>
        <Lottie
          isClickToPauseDisabled
          options={defaultOptionsAnimation}
          height='auto'
          width='100%'
        />
        <img src={logo} alt='logo' />
      </div>
      <div className='col-span-12 md:col-span-12 lg:col-span-6 mb-5 md:mb-0 flex justify-center items-center h-full'>
        <div className='mx-auto w-3/4 mb-12'>
          <FormLogin
            loading={!!(loading || loadingME)}
            error={error}
            onFinishForm={onFinishForm}
            title='Bienvenidos a BioAnalisis'
            description=''
            visibleButtonCreateAccount
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LoginMD);
